'use strict';

var scrollAnimate = require('base/components/scrollAnimate');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(success, msg) {
    var status = success ? 'alert-success' : 'alert-danger';

    if ($('.email-signup-message').length === 0) {
        $('body').append('<div class="email-signup-message"></div>');
    }

    $('.email-signup-message').append('<div class="email-signup-alert text-center alert ' + status + '">' + msg + '</div>');

    setTimeout(function () {
        $('.email-signup-message').remove();
    }, 3000);
}

var footer = {
    'elementNameMap': {
        'hpEmailSignUp': 'emailId'
    },
    'backToTop': function () {
        $('.back-to-top').click(function () {
            scrollAnimate();
        });
    },
    'forms': 'footer .email-signup-form form',
    'init': function () {
        var nameMap = this.elementNameMap;

        // Prevent Multi Form event collision
        var $forms = $(this.forms);
        $forms.each(function () {
            var $form = $(this);
            var $inputs = $form.find(':input:not(:disabled)');

            //TODO: Security Update with CSRF

            // Update Form Name for SFRA compatibility
            if (nameMap) {
                $inputs.each(function () {
                    var $input = $(this);
                    var oldName = $input.attr('name');
                    var newName = oldName && nameMap[oldName];

                    if (newName) {
                        $input.attr('name', newName);
                    }
                });
            }

            $form.on('submit', function (e, urlOverride) {
                e.preventDefault();

                var $btn = $(this);
                var xhr = $form.data('xhr');
                $form.data('xhr', $.ajax({
                    url: urlOverride || $form.attr('action'),
                    type: $form.attr('method') || 'post',
                    data: $inputs.serialize(),
                    dataType: 'json',
                    beforeSend: function () {
                        // Drop duplicate requests
                        xhr && xhr.abort && xhr.abort();

                        $.spinner().start();

                        $btn.attr('disabled', true);
                    },
                    success: function (data) {
                       displayMessage(data.success, data.msg);
                       var mcAnalyticsEnabled =  $('.mcEnableAnalytics').data('sfmc-mcenableanalytics');
                        if (mcAnalyticsEnabled && _etmc){
                            var locale =  $('body').data('locale');
               				var mcMID =  $('.sfmcMID').data('sfmc-mid');
        					_etmc.push(["setOrgId",mcMID]);		                            
                            _etmc.push(["setUserInfo", { "email" : data.email, "email_sha256" : data.hashedEmail, "details": { "locale": locale}}]);
                        	_etmc.push(["trackPageView",  { "title" : document.title}]);
                        }
		                        
                    },
                    error: function ($xhr, err, other) {
                        var msg = $xhr.responseJSON && $xhr.responseJSON.message;
                        displayMessage(false, msg);
                    },
                    complete: function () {
                        $.spinner().stop();
                        $btn.removeAttr('disabled');
                        $form[0].reset();
                    }
                }));
            })

            // For SFRA compatibility
            $form.find('.subscribe-email').on('click', function (e) {
                e.preventDefault();
                $form.trigger('submit', $(this).data('href'));
            });
        });
    }
};

$(document).ready(function(){
    $('.ootb-bundle').each(function(){  
        var highestBox = 0;

        $(this).find('.product-name').each(function(){
            if($(this).height() > highestBox){  
                highestBox = $(this).height();  
            }
        })
        $(this).find('.product-name').height(highestBox);
    });    
});

module.exports = footer;

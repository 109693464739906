'use strict';
var imageRecognition = require('../components/imageRecognition');

function showCustomizer(product, productDetails, locale, translations, apiKey, version, cyo_date, fullSite, addToCartURL, finalMessage) {
    CustomizerWidget.default.createWidget({
        // Pass API key to Customizer.
        /**
         * TODO: transfer the location of the apiKey
         * Since js file will not be able to access site preference, would it be a good idea to put this value in data attribute?
         */
        apiKey: apiKey,

        // Start customizing this product.
        products: product,
        // preview: cyo_date,
        // Use the default site for now.
        site: fullSite,
        poptivismMode: false,
        uploadOnly: productDetails.uploadOnly,
        showPricing: productDetails.showpricing,
        allowChangeProduct: productDetails.allowchangeproduct,
        version: version && version !== '' ? version : null,
        translations: translations,
        locale: locale,

        productDataEndpoint: (options, done) => {
            const products = [];

            if (options.products) {
                options.products.forEach((product) => {
                    products.push({
                        productId: product.productId,
                        online: productDetails.online,
                        ats: 100,
                        price: productDetails.price,
                    });
                });
            }

            // Imitate a network delay.
            setTimeout(() => {
                done(products);
            });
        },

        // Simple price formatter.
        // Ideally we'd take browser/site locale into account.
        formatPriceEndpoint: (price) => {
            return '$' + price;
        },

        onCancel: () => {
            // Do nothing, just close the overlay.
        },

        onDone: (result) => {
            // Done. Process resulting recipe.
            if (result.type === 'custom' && result.recipe.location) {
                // Use fetch, jquery, etc to download the recipe and parse it.
                fetch(result.recipe.location)
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (response) {
                        processResponse(response, addToCartURL, finalMessage);
                    });
            }
        },
    });
}

function setChildProducts(id) {
    var childProducts = [];

    childProducts.push({
        pid: id.trim(),
        quantity: null
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

function processImages(views, suffix) {
    if (suffix == null) {
        suffix = '';
    }

    var image = {
        'expanded': '',
        'product': '',
        'oms': ''
    };

    if (views) {
        for (let i=0; i<views.length; i++) {
            var view = views[i];

            if (view.code.toLowerCase() === 'product' + suffix) {
                image['product'] = view.previewPng;
            }

            if (view.code.toLowerCase() === 'omsfront1' + suffix) {
                image['oms'] = view.previewPng;
            }
        }
    }

    return image;
}

function showWidget(product, currency, locale, translations, apiKey, fullSite, productDataUrl, formatPriceUrl, addToCartURL, finalMessage, isRecipe) {
    var config = {
        apiKey: apiKey,

        // disableCaching: true,

        site: fullSite,

        currency: currency,

        translations,

        locale,

        // preview: '1/1/2099',


        productDataEndpoint: (options, done) => {
            //append additional product ids in url
            var url = productDataUrl + ',' + options.toString();
            $.ajax({
                type: 'GET',
                url: url,
                dataType: 'json'
            }).done(function (result) {
                done(result.productData);
            });
        },

        formatPriceEndpoint: (price) => {
            $.ajax({
                type: 'GET',
                url: formatPriceUrl,
                dataType: 'json'
            }).done(function (result) {
                return result.price;
            });
        },

        onCancel: () => {
        },

        onDone: (result) => {
            // Done. Process resulting recipe.
            if (result.location) {
                // Use fetch, jquery, etc to download the recipe and parse it.
                fetch(result.location)
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (response) {
                        processResponse(response, addToCartURL, finalMessage);
                    });
            }
        },

        onGetPrice: (products, next) => {
            products.forEach((p) => {
                p.price = '$10';
            });

            next(products);
        },

        container: '#app',
    };

    
    // Starting product for all things grips
    if(isRecipe === "true" ) {
        config.recipe = product;
    } else {
        config.products = product;
    }

    if ($('.product-customizer.third-party-product').data('config')) {
        config.startingParameters = $('.product-customizer.third-party-product').data('config');
    }

    CustomizerWidget.createWidget(config);
}

function processResponse(response, addToCartURL, finalMessage) {
    var htmlString = '<!-- Modal -->'
        + '<div class="modal show" id="product-customizer-popup" tabindex="-1" role="dialog" style="display:block;">'
        + '<div class="modal-dialog product-customizer-modal-dialog">'
        + '  <!-- Modal content-->'
        + '  <div class="modal-content"></div>'
        + '</div>'
        + '</div>'
        + '<div class="product-customizer-fade modal-backdrop fade show">'
        + '</div>';
        $('body').append(htmlString);

    var waitForAdd = [];
    var lastData = null;

    var addRegularProduct = function () {
        var sku = response.custom.sku;
        var tla = response.custom.tla;

        var customizeImages = processImages(response.views);
        var omsImage = customizeImages['oms'];

        if (customizeImages['product']) {
            omsImage = customizeImages['product'];
        }

        var imageUrl = [];
        //PAP-2586 start: CYO 3.0, get orignial uploaded images to be send to image recognition
        for (var key in response.custom) {
            if (key.startsWith('ps-recipe-source')) {
                $.ajax({
                    url: 'https://cz.drrv.co/recipe/'+response.custom[key]+'.json',
                    type: 'get',
                    async: false,
                    success: function (recipeResp) {
                        for(var i = 0; i < recipeResp.components.length; i++) {
                            if (recipeResp.components[i].component.custom.hasOwnProperty('dynamic-image-jpgUrl')) {
                                imageUrl.push(recipeResp.components[i].component.custom['dynamic-image-jpgUrl']);
                            }
                        }
                    }
                });
            }
        }
        //PAP-2586 end

        var form = {
            pid: sku,
            pidsObj: {},
            childProducts: setChildProducts(tla),
            quantity: '1',
            customProduct: true,
            omsImage: omsImage,
            recipeID: response.id,
            imageUrl: imageUrl.length > 0 ? JSON.stringify(imageUrl) : ''
        };

        waitForAdd.push($.ajax({
            url: addToCartURL,
            method: 'POST',
            data: form,
            success: function (data) {
                lastData = data;
                if (typeof cvg != 'undefined' && data.convergeTrackingData) { cvg(data.convergeTrackingData);}
            }
        }));
    }

    // Check if there are Nails involved.
    if (response.custom['has-nails'] == 'true') {
        var sku = response.custom['sku-nails'];
        var tla = response.custom['tla-nails'];

        var customizeImages = processImages(response.views, '-nails');
        var omsImage = customizeImages['oms'];

        if (customizeImages['product']) {
            omsImage = customizeImages['product'];
        }

        var form = {
            pid: sku,
            pidsObj: {},
            childProducts: setChildProducts(tla),
            quantity: '1',
            customProduct: true,
            omsImage: omsImage,
            recipeID: response.id
        };

        waitForAdd.push($.ajax({
            url: addToCartURL,
            method: 'POST',
            data: form,
            success: function (data) {
                lastData = data;
            }
        }));

        // Special case, check if there is an attached PopGrip and create a separate line item.
        if (response.components) {
            var hasGrip = false;

            response.components.forEach(function (component) {
                if (component.code === 'GRIPIMAGE' && component.component.custom && component.component.custom['dynamic-image-baked'] && component.component.custom['dynamic-image-baked'].length > 0) {
                    hasGrip = true;
                }
            });

            if (hasGrip) {
                // If there is a customized regular grip, add it to cart.
                addRegularProduct();
            }
        }
    } else {
        // Otherwise add PopGrip or another similar standalone product.
        addRegularProduct();
    }

    // Check if there are order-extra-sku-1 involved. 
    if (response.custom['order-extra-sku-1']) {
        var form = {
            pid: response.custom['order-extra-sku-1'],
            pidsObj: {},
            quantity: '1',
            customProduct: true,
            omsImage: omsImage,
            recipeID: response.id,
        };

        waitForAdd.push($.ajax({
            url: addToCartURL,
            method: 'POST',
            data: form,
            success: function (data) {
                lastData = data;
            }
        }));
    }

    // Wait until all products are added to cart.
    $.when.apply($, waitForAdd).done(function () {
        $('.minicart').trigger('count:update', lastData);
        $('body').trigger('product:afterAddToCart', lastData);

        if (lastData.error) {
            $('#product-customizer-popup').remove();
            $('.product-customizer-fade').remove();
            if ($('.product-customizer-error').length) {
                $('.product-customizer-error').empty().html(lastData.message);
            } else {
                $('body').append('<div class="toast-messages"></div>');
                $('.toast-messages').append('<div class="alert text-center text-center alert-danger">'+ lastData.message +'</div>');
                setTimeout(function () {
                    $('.toast-messages').remove();
                }, 3000);
            }
        } else {
            $.ajax({
                url: finalMessage,
                type: 'get',
                dataType: 'html',
                success: function (response) {
                    $('.product-customizer-modal-dialog .modal-content').html(response);
                    $('#product-customizer-popup .modal-header button.close').click(function (e) {
                        e.preventDefault();
                            $('#product-customizer-popup .modal-body').remove();
                            $('#product-customizer-popup').remove();
                            $('.product-customizer-fade').remove();
                    });
                },
                error: function () {
                    $('#product-customizer-popup').remove();
                }
            }).done(function () {
                imageRecognition.processCYOImages();
            });;
        }
    }).fail(function () {
        $('#product-customizer-popup').remove();
    });
}

module.exports = {

    productCustomizer: function () {
        /**
         * TODO: as discussed, parameter of ShowCustomizer should be the product id
         */
        $('body').on('click', '.product-customizer-pdp', function () {
            $(this).addClass('product-customizer-active');
            var productDetails = $(this).data('pdetails');
            var initValue = $(this).is('.customizer-upgrade') ? $(this).attr('data-initvalue') : $('.product-customizer-init-data').attr('data-initvalue');
            var isRecipe = $('.product-customizer-init-data').attr('data-recipe');
            var translationDetails = $('.productcustomizer-translations-details');
            var cyo_date = $('.product-customizer-cyo_preview').data('cyo_date');
            var productDataUrl = $(this).attr('data-productData-url');
            //avoid price special characters encoding
            var formatPriceUrl = $(this).attr('data-formatPrice-url') +'?value='+ $(this).attr('data-price');
            if (cyo_date == 'null') {
                cyo_date = '';
            }

            var locale = translationDetails.find('.locale-id').val();
            var currency = translationDetails.find('.currency').val();
            var apiKey = translationDetails.find('.apiKey').val();
            var fullSite = 'SFCC,SFCC-' + translationDetails.find('.locale-id').val().substr(-2);
            
            var translations = {};
            var translationValue = JSON.parse(translationDetails.find('.translations-value').val().trim());
            translations[locale] = translationValue;

            var addToCartURL = $('.product-customizer-pdp').data('add-to-cart-url');
            var finalMessage = $('.product-customizer-pdp').data('result-url');

            /**
             * Trigger customizer widget after customizer script is loaded.
             */
            $.spinner().start();
            function TriggerCustomizerWidget () {
                //check if customizer script loaded
                if (typeof CustomizerWidget != 'undefined') {
                    if ($('.product-detail.product-wrapper').data('use-cyo3') === true) {
                        showWidget(initValue, currency, locale, translations, apiKey, fullSite, productDataUrl, formatPriceUrl, addToCartURL, finalMessage, isRecipe);
                    } else {
                        showCustomizer(initValue, productDetails, locale, translations, apiKey, null, cyo_date, fullSite, addToCartURL, finalMessage);
                    }
                    $.spinner().stop();
                } else {
                    setTimeout(function() {
                        TriggerCustomizerWidget();
                    }, 2000);
                }
            }
            TriggerCustomizerWidget();
        });

        $('body').on('click', '.create-another-customizer', function () {
            $('body .product-customizer-active').click();
        });

        $('body').on('change', '.custom-cases-select', function () {
            var device = $(this).children("option:selected").attr('data-attr-value');

            if (device != undefined && device != 'Cases') {
                $('.product-customizer-pdp.btn').removeAttr('disabled');
                $('.product-customizer-init-data').attr('data-initvalue', device);
            } else {
                $('.product-customizer-pdp.btn').attr('disabled', 'disabled');
                $('.product-customizer-init-data').attr('data-initvalue', 'Cases');
            }
        });

        if ($('.custom-cases-select').length) {
            var bundleId = $('.bundle-item.custom-bundle').data('bundleid');
            if (bundleId === '13-002957') {
                $('[data-attr-value=iPhone78]').attr('data-attr-value', 'OtterPop78');
                $('[data-attr-value=iPhone78plus]').attr('data-attr-value', 'OtterPop7Plus8Plus');
                $('[data-attr-value=iPhonexxs]').attr('data-attr-value', 'OtterPopXXS');
                $('[data-attr-value=iPhonexr]').attr('data-attr-value', 'OtterPopXR');
                $('[data-attr-value=iPhonexsmax]').attr('data-attr-value', 'OtterPopXSMax');
                $('[data-attr-value=iPhone11]').attr('data-attr-value', 'OtterPop11');
                $('[data-attr-value=iPhone11pro]').attr('data-attr-value', 'OtterPop11Pro');
                $('[data-attr-value=iPhone11promax]').attr('data-attr-value', 'OtterPop11ProMax');
                $('[data-attr-value=iPhone12]').attr('data-attr-value', 'OtterPop12');
                $('[data-attr-value=iPhone12promax]').attr('data-attr-value', 'OtterPop12ProMax');
                $('[data-attr-value=iPhone12mini]').attr('data-attr-value', 'OtterPop12Mini');
            } else if (bundleId == '13-004961') {
                $('[data-attr-value=iPhone12]').attr('data-attr-value', 'PopCase12');
                $('[data-attr-value=iPhone12promax]').attr('data-attr-value', 'PopCase12Max');
                $('[data-attr-value=IPhone13]').attr('data-attr-value', 'PopCase13'); // 'IPhone' capitalized intentionally
                $('[data-attr-value=iPhone13pro]').attr('data-attr-value', 'PopCase13Pro');
                $('[data-attr-value=iPhone13promax]').attr('data-attr-value', 'PopCase13ProMax');
            }
            $('.custom-cases-select').trigger('change');
        }

        $('body').on('click', '.product-customizer', function (e) {
            e.preventDefault();
            e.stopPropagation();
            $('.product-customizer').removeClass('product-customizer-active');
            
            $(this).addClass('product-customizer-active');
            var initValue = $(this).attr('data-initvalue');
            var isRecipe =  $(this).attr('data-recipe');
            var translationDetails = $('.productcustomizer-translations-details');
            var cyo_date = $('.product-customizer-cyo_preview').data('cyo_date');
            var productDataUrl = translationDetails.attr('data-productData-url') +'?products='+$(this).attr('data-pid');
            //avoid price special characters encoding
            var formatPriceUrl = translationDetails.attr('data-formatPrice-url');
            if (cyo_date == 'null') {
                cyo_date = '';
            }

            var locale = translationDetails.find('.locale-id').val();
            var currency = translationDetails.find('.currency').val();
            var apiKey = translationDetails.find('.apiKey').val();
            var fullSite = 'SFCC,SFCC-' + translationDetails.find('.locale-id').val().substr(-2);
            
            var translations = {};
            var translationValue = JSON.parse(translationDetails.find('.translations-value').val().trim());
            translations[locale] = translationValue;

            var addToCartURL = translationDetails.attr('data-add-to-cart-url');
            var finalMessage = translationDetails.attr('data-result-url');

            /**
             * Trigger customizer widget after customizer script is loaded.
             */
            $.spinner().start();
            function TriggerWidget () {
                //check if customizer script loaded
                if (typeof CustomizerWidget != 'undefined') {
                    showWidget(initValue, currency, locale, translations, apiKey, fullSite, productDataUrl, formatPriceUrl, addToCartURL, finalMessage, isRecipe);
                    $.spinner().stop();
                } else {
                    if (!document.getElementById('customizer-script')) {
                        let customizerScript= document.createElement('script');
                        customizerScript.id = 'customizer-script';
                        customizerScript.src = $('.customizer-script-src').attr('data-customizer-src');
                        customizerScript.async = true;
                        document.head.appendChild(customizerScript);
                    }
                    setTimeout(function() {
                        TriggerWidget();
                    }, 2000);
                }
            }
            TriggerWidget();
        }); 

        if ($('.third-party-product').length) {
            /**
             * Trigger customizer widget after customizer script is loaded.
             */
            function TriggerCustomizer () {
                //check if customizer script loaded
                if (typeof CustomizerWidget != 'undefined') {
                    $('.product-customizer.third-party-product').trigger('click');
                } else {
                    setTimeout(function() {
                        TriggerCustomizer();
                    }, 2000);
                }
            }
            TriggerCustomizer();
        }
    }
};